<template>
  <Teleport to="body">
    <div v-if="isShown" class="modal fade" ref="canvas-template-modal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content rounded-5 p-3">
          <div class="modal-header">
            <div class="w-100 d-flex flex-wrap align-items-center">
              <input type="search" class="px-4 border-0 rounded-5" :placeholder="`&#xF52A;&nbsp;${$t('검색')}`"
                     style="width: 21rem; height:4rem; background-color: #EAEAEA"
                     @input="onSearch">
              <dot-button type="button" class="bg-dot-gray rounded-5" style="width: 10rem; height:4rem" data-bs-dismiss="modal">Cancel</dot-button>
              <dot-button type="button" class="bg-dot-orange rounded-5" style="width: 10rem; height:4rem" @click="onSelectButton">Select</dot-button>
            </div>
          </div>
          <div class="modal-body">
            <div class="anticipated">
              <p class="fs-5">Lines</p>
              <div class="d-flex flex-wrap gap-3">
                <span :data-keyword="[$t('선'),$t('직선')]">
                  <dot-radio-button name="shape" id="solid-line" modelValue="Line" v-model="shape" :width="width" :height="height">
                    <!-- No dashes nor gaps -->
                    <svg width="36" height="4" viewBox="0 0 36 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 2H34" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </dot-radio-button>
                </span>
                <span :data-keyword="[$t('선'),$t('점선')]">
                  <dot-radio-button name="shape" id="dashed-line" modelValue="DashedLine" v-model="shape" :width="width" :height="height">
                    <!-- Dashes and gaps of the same size -->
                    <svg width="40" height="4" viewBox="0 0 40 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 2H38" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="6 7"/>
                    </svg>
                  </dot-radio-button>
                </span>
                <span :data-keyword="[$t('선'),$t('점선')]">
                  <dot-radio-button name="shape" id="dotted-line" modelValue="DottedLine" v-model="shape" :width="width" :height="height">
                    <svg width="24" height="4" viewBox="0 0 24 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <title>dotted line</title>
                      <!-- Dashes and gaps of different sizes -->
                      <line x1="0" y1="5" x2="30" y2="5" stroke="black" stroke-width="4" stroke-dasharray="4 1"/>
                    </svg>
                  </dot-radio-button>
                </span>
              </div>
            </div>
            <hr class="hr">
            <div class="anticipated">
              <p class="fs-5">Numbers</p>
              <div class="d-flex flex-wrap gap-3">
                <span :data-keyword="[]"><dot-radio-button name="shape" id="one" modelValue="One" v-model="shape" :width="width" :height="height" disabled>1</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="two" modelValue="Two" v-model="shape" :width="width" :height="height" disabled>2</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="three" modelValue="Three" v-model="shape" :width="width" :height="height" disabled>3</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="four" modelValue="Four" v-model="shape" :width="width" :height="height" disabled>4</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="five" modelValue="Five" v-model="shape" :width="width" :height="height" disabled>5</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="six" modelValue="Six" v-model="shape" :width="width" :height="height" disabled>6</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="seven" modelValue="Seven" v-model="shape" :width="width" :height="height" disabled>7</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="eight" modelValue="Eight" v-model="shape" :width="width" :height="height" disabled>8</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="nine" modelValue="Nine" v-model="shape" :width="width" :height="height" disabled>9</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="zero" modelValue="Zero" v-model="shape" :width="width" :height="height" disabled>0</dot-radio-button></span>
              </div>
            </div>
            <hr class="hr">
            <div class="anticipated">
              <p class="fs-5">Calculation</p>
              <div class="d-flex flex-wrap gap-3">
                <span :data-keyword="[]"><dot-radio-button name="shape" id="plus" modelValue="Plus" v-model="shape" :width="width" :height="height" disabled>&plus;</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="minus" modelValue="Minus" v-model="shape" :width="width" :height="height" disabled>&minus;</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="times" modelValue="Times" v-model="shape" :width="width" :height="height" disabled>&times;</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="div" modelValue="Div" v-model="shape" :width="width" :height="height" disabled>&div;</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="equals" modelValue="Equals" v-model="shape" :width="width" :height="height" disabled>&equals;</dot-radio-button></span>
              </div>
            </div>
            <hr class="hr">
            <div class="anticipated">
              <p class="fs-5">English</p>
              <div class="d-flex flex-wrap gap-3">
                <span :data-keyword="[]"><dot-radio-button name="shape" id="a" modelValue="A" v-model="shape" :width="width" :height="height" disabled>A</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="b" modelValue="B" v-model="shape" :width="width" :height="height" disabled>B</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="c" modelValue="C" v-model="shape" :width="width" :height="height" disabled>C</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="d" modelValue="D" v-model="shape" :width="width" :height="height" disabled>D</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="e" modelValue="E" v-model="shape" :width="width" :height="height" disabled>E</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="f" modelValue="F" v-model="shape" :width="width" :height="height" disabled>F</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="g" modelValue="G" v-model="shape" :width="width" :height="height" disabled>G</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="h" modelValue="H" v-model="shape" :width="width" :height="height" disabled>H</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="i" modelValue="I" v-model="shape" :width="width" :height="height" disabled>I</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="j" modelValue="J" v-model="shape" :width="width" :height="height" disabled>J</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="k" modelValue="K" v-model="shape" :width="width" :height="height" disabled>K</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="l" modelValue="L" v-model="shape" :width="width" :height="height" disabled>L</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="m" modelValue="M" v-model="shape" :width="width" :height="height" disabled>M</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="n" modelValue="N" v-model="shape" :width="width" :height="height" disabled>N</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="o" modelValue="O" v-model="shape" :width="width" :height="height" disabled>O</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="p" modelValue="P" v-model="shape" :width="width" :height="height" disabled>P</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="q" modelValue="Q" v-model="shape" :width="width" :height="height" disabled>Q</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="r" modelValue="R" v-model="shape" :width="width" :height="height" disabled>R</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="s" modelValue="S" v-model="shape" :width="width" :height="height" disabled>S</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="t" modelValue="T" v-model="shape" :width="width" :height="height" disabled>T</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="u" modelValue="U" v-model="shape" :width="width" :height="height" disabled>U</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="v" modelValue="V" v-model="shape" :width="width" :height="height" disabled>V</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="w" modelValue="W" v-model="shape" :width="width" :height="height" disabled>W</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="x" modelValue="X" v-model="shape" :width="width" :height="height" disabled>X</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="y" modelValue="Y" v-model="shape" :width="width" :height="height" disabled>Y</dot-radio-button></span>
                <span :data-keyword="[]"><dot-radio-button name="shape" id="z" modelValue="Z" v-model="shape" :width="width" :height="height" disabled>Z</dot-radio-button></span>
              </div>
            </div>
            <hr class="hr">
            <div class="anticipated">
              <p class="fs-5">Shapes</p>
              <div class="d-flex flex-wrap gap-3">
                <span :data-keyword="[$t('도형'),$t('동그라미'),$t('원')]">
                  <dot-radio-button name="shape" id="circle" modelValue="Circle" v-model="shape" :width="width" :height="height">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <title>1 circle</title>
                      <path d="M26 14C26 20.6 20.6 26 14 26C7.4 26 2 20.6 2 14C2 7.4 7.4 2 14 2C20.6 2 26 7.4 26 14Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </dot-radio-button>
                </span>
                <span :data-keyword="[$t('도형'),$t('네모'),$t('사각형')]">
                  <dot-radio-button name="shape" id="square" modelValue="Square" v-model="shape" :width="width" :height="height">
                    <title>1 square</title>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.4 26H17.6C23.6 26 26 23.6 26 17.6V10.4C26 4.4 23.6 2 17.6 2H10.4C4.4 2 2 4.4 2 10.4V17.6C2 23.6 4.4 26 10.4 26Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>                </svg>
                  </dot-radio-button>
                </span>
                <span :data-keyword="[$t('도형'),$t('세모'),$t('삼각형')]">
                  <dot-radio-button name="shape" id="triangle" modelValue="Triangle" v-model="shape" :width="width" :height="height">
                    <title>1 triangle</title>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.6392 20L3.32555 20C2.23915 20 1.6356 18.7692 2.23915 17.9077L11.896 2.64615C12.3788 1.78462 13.5859 1.78462 14.1895 2.64615L23.8463 17.9077C24.3292 18.8923 23.6049 20 22.6392 20Z"  stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"  stroke-linejoin="round"/>
                    </svg>
                  </dot-radio-button>
                </span>
                <span :data-keyword="[]">
                  <dot-radio-button name="shape" id="star" modelValue="Star" v-model="shape" :width="width" :height="height" disabled>
                  <title>1 star</title>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.43903 7.20202L12.1328 2.51041C12.5171 1.84098 13.4829 1.84098 13.8672 2.51041L16.561 7.20202C16.706 7.45467 16.9541 7.6315 17.2402 7.68627L23.141 8.81563C23.9302 8.96668 24.228 9.93977 23.6584 10.5066L19.6596 14.4864C19.4425 14.7025 19.3364 15.0061 19.3716 15.3104L20.0551 21.2055C20.1456 21.9862 19.3416 22.5614 18.632 22.2236L13.4298 19.7474C13.1579 19.618 12.8421 19.618 12.5702 19.7474L7.36802 22.2236C6.65839 22.5614 5.85438 21.9862 5.94489 21.2055L6.62835 15.3104C6.66363 15.0061 6.55752 14.7025 6.34042 14.4864L2.34156 10.5066C1.77201 9.93977 2.06978 8.96668 2.85899 8.81563L8.75979 7.68627C9.04593 7.6315 9.29397 7.45467 9.43903 7.20202Z" stroke="black" stroke-width="3"/>
                    </svg>
                  </dot-radio-button>
                </span>
              </div>
            </div>
            <hr class="d-none">
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import {Modal} from "bootstrap";
import { defineAsyncComponent } from 'vue'

const DotButton = defineAsyncComponent(() => import('@/components/atoms/DotButton.vue'))
const DotRadioButton = defineAsyncComponent(() => import('@/components/atoms/DotRadioButton.vue'))

export default {
  name: "CanvasTemplate",
  components: {
    DotButton,
    DotRadioButton
  },
  emits: ['handleSelect'],
  data() {
    return {
      modal: null,
      isShown: false,
      shape: null,
      width: "4.5rem",
      height: "4.5rem",
      pick: null
    }
  },
  methods: {
    open() {
      this.isShown = true;
        this.$nextTick(() => {
          const modalEl = this.$refs["canvas-template-modal"];
          modalEl.addEventListener('hide.bs.modal', this.close);

          this.modal = Modal.getOrCreateInstance(modalEl);
          this.modal.show();
        });
    },
    close() {
      this.isShown = false;
    },
    onSelectButton() {
      this.$emit("handleSelect", this.shape);
      this.modal.hide();
    },
    onSearch(event) {
      const searchTerm = event.target.value;

      this.hideItems(searchTerm);
      this.hideCategoryElements();
      this.hideHrs();
      this.hideLastHr();
    },
    hideItems(searchTerm) {
      this.$refs["canvas-template-modal"]
        .querySelectorAll("[data-keyword]")
        .forEach((element) => {
          const keyword = element.dataset.keyword.toLowerCase();
          keyword.includes(searchTerm.toLowerCase()) ? element.classList.remove("d-none") : element.classList.add("d-none");
        });
    },
    hideCategoryElements() {
      this.$refs["canvas-template-modal"]
        .querySelectorAll(".anticipated")
        .forEach((element) => {
          const spanLength = element.querySelectorAll("div > span").length;
          const spanDNoneLength = element.querySelectorAll("div > span.d-none").length;

          spanLength === spanDNoneLength ? element.classList.add("d-none") : element.classList.remove("d-none");
        });
    },
    hideHrs() {
      this.$refs["canvas-template-modal"]
        .querySelectorAll(".anticipated + hr")
        .forEach((element) => {
          element.previousElementSibling.classList.contains("d-none") ? element.classList.add("d-none") : element.classList.remove("d-none");
        });
    },
    hideLastHr() {
      const hrElements = this.$refs["canvas-template-modal"].querySelectorAll(".anticipated:not(.d-none) + hr");

      if (hrElements.length > 0) {
        const lastHrElement = hrElements[hrElements.length - 1];
        lastHrElement.classList.add("d-none");
      }
    }
  }
}
</script>

<style scoped>
.modal-header {
  border-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

input[type="search"] {
  padding-left: 1rem;
}

input[type="search"] {
  font-family: "Helvetica Neue", sans-serif, bootstrap-icons;
  font-size: 1.5rem;
}

input[type="search"]::placeholder {
  text-align: center;
}
.align-items-center .bg-dot-gray {
  margin: 0 17px;
}
.hr {
  margin: 2rem 0;
}
.fs-5 {
  line-height: 1;
}
</style>
